<template>
  <div id="account">

    <v-toolbar flat>
      <v-text-field :value="search" @change="value => search = value" placeholder="名前またはメール" clearable hide-details dense filled rounded prepend-inner-icon="mdi-magnify"></v-text-field>
      <v-spacer />
      <v-btn color="primary" :to="{ name: 'admin:settings_login_new_form' }" elevation="0"><v-icon left>mdi-account-plus</v-icon>新規登録</v-btn>
    </v-toolbar>
    <v-container>

          <v-data-table class="clickable" :headers="headers" :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100, 250, 500, 1000], showFirstLastPage: true }" :items="accounts" :options.sync="options" :server-items-length="totalPassengers" :loading="loading" @click:row="changeView">
            <template v-slot:item.photo="{ item }">
              <LoginAvatar :user="item" :size="42" class="my-1" />
            </template>
            <template v-slot:item.name="{ item }">

              <div class="d-flex justify-space-between">
                <ruby class="">{{ item.name }}<rt>{{ item.kana }}</rt></ruby>
                <div>
                  <v-tooltip top v-if="item.email">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small class="" icon><v-icon small v-bind="attrs" v-on="on" @click.stop="copyClipboard(item.email)">mdi-email</v-icon></v-btn>
                    </template>
                    <span>{{item.email}}</span>
                  </v-tooltip>
                  <v-tooltip top v-if="item.phone">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small class="" icon><v-icon small v-bind="attrs" v-on="on"  @click.stop="copyClipboard(item.phone)">mdi-cellphone</v-icon></v-btn>
                    </template>
                    <span>{{item.phone}}</span>
                  </v-tooltip>
                </div>
              </div>
            </template>
            <template v-slot:item.create_at="{ item }">
              {{ item.create_at | moment("YYYY/MM/DD") }}
            </template>
            <template v-slot:item.last_login_at="{ item }">
              {{ item.last_login_at | moment("YYYY/MM/DD HH:mm") }}
            </template>
          </v-data-table>

    </v-container>
    <message-bar ref="message" />
  </div>
</template>

<script>
import axios from '@/plugins/axios'
import MessageBar from '../../components/MessageBar.vue'
import LoginAvatar from '@/components/LoginAvatar.vue'

export default {
  components: { MessageBar, LoginAvatar },
  name: 'account',
  methods: {
    changeView: function (value) {
      const uid = value.id
      if (uid) this.$router.push({ name: 'admin:settings_login_show', params: { id: uid } })
    },
    readDataFromAPI: function () {
      this.loading = true
      const queryParams = {
        sort: this.options.sortBy.length === 0 ? 'name' : this.options.sortBy[0],
        direction: this.options.sortDesc.length === 0 ? 'asc' : (this.options.sortDesc[0] ? 'desc' : 'asc'),
        per_page: this.options.itemsPerPage > 0 ? this.options.itemsPerPage : 0,
        page: this.options.page
      }
      if (this.search) {
        queryParams.query = this.search
      }
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/login`, {
        params: queryParams
      })
        .then(res => {
          this.loading = false
          this.accounts = res.data.data
          this.totalPassengers = res.data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    copyClipboard (str) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(str)
        this.$refs.message.show(`${str}をクリップボードにコピーしました`)
      }
    }
  },
  data: function () {
    return {
      accounts: [],
      search: '',
      loading: true,
      totalPassengers: 0,
      options: {},
      headers: [
        {
          text: '',
          width: 10,
          sortable: false,
          value: 'photo',
          cellClass: 'cell-avatar'
        },
        {
          text: '名前',
          value: 'name'
        },
        {
          text: '登録日',
          value: 'create_at',
          align: 'center',
          width: 120
        },
        {
          text: '最終ログイン日時',
          value: 'last_login_at',
          align: 'center',
          width: 160
        }
      ]
    }
  },
  watch: {
    search: function () {
      this.options.page = 1
      this.readDataFromAPI()
    },
    options: {
      handler () {
        this.readDataFromAPI()
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .cell-avatar{
  padding-right: 0!important;
}
</style>
